/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .prose p,
  .prose h2,
  .prose h3,
  .prose ul,
  .prose ol,
  .prose table,
  .prose blockquote {
    @apply max-w-2xl mx-auto;
  }
  .instructions ol {
    counter-reset: item;
  }
  .instructions ol li:before {
    white-space: pre;
    @apply font-bold;
    content: "Step " counter(item) "\A";
    counter-increment: item;
  }
}

@layer utilities {
  .font-heading {
    /* @apply font-bold; */
  }
}
